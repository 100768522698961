@import "./vars.css";

:global(.modal-open) {
  overflow: hidden;
}

.app {
  position: relative;
  z-index: 1;
}

.content {
  display: flex;
  height: 100vh;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: #fff;
  @media (min-width: 0px) and (max-width: 1199px) {
    flex-direction: column;
    height: auto;
  }
}

.leftPanel,
.rightPanel {
  width: 50%;
  @media (min-width: 0px) and (max-width: 1199px) {
    width: 100%;
  }
}

.leftPanel {
  padding-left: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 0px) and (max-width: 1199px) {
    padding: 0;
    align-items: center;
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    padding-left: 80px;
  }
}

.logo {
  margin-bottom: 30px;
  @media (min-width: 0px) and (max-width: 1199px) {
    margin-top: 30px;
  }
}

.mainHeading {
  margin: 0 0 50px 0;
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 55px;
  line-height: 66px;
  letter-spacing: -1.5px;
  @media (min-width: 0px) and (max-width: 768px) {
    font-size: 42px;
    line-height: 47px;
    padding: 0 30px;
  }
  @media (min-width: 0px) and (max-width: 1199px) {
    max-width: 612px;
  }
}

.mainHeadingLine {
  display: block;
  position: relative;
  @media (min-width: 0px) and (max-width: 1199px) {
    display: inline-block;
  }
  &:first-child {
    &:after {
      content: "";
      position: absolute;
      bottom: -10px;
      display: block;
      width: 439px;
      height: 30px;
      background-image: url("../assets/arrow.svg");
      background-repeat: no-repeat;
      z-index: -1;
    }
    @media (min-width: 0px) and (max-width: 1199px) {
      &:after {
        display: none;
      }
    }
  }
}

.secondaryHeading {
  margin: 0 0 10px 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 22px;
  line-height: 29.5px;
  @media (min-width: 0px) and (max-width: 768px) {
    padding: 0 30px;
  }
}

.text {
  margin: 0;
  line-height: 140%;
  @media (min-width: 0px) and (max-width: 768px) {
    padding: 0 30px;
  }
}

.infoBlock {
  width: 347px;
  &:not(:last-child) {
    margin-bottom: 50px;
    width: 390px;
    box-sizing: content-box;
    @media (min-width: 0px) and (max-width: 1199px) {
      padding-left: 0;
      width: 100%;
      max-width: 354px;
      margin-bottom: 0;
    }
  }
  @media (min-width: 0px) and (max-width: 1199px) {
    padding-left: 0;
    width: 100%;
    max-width: 354px;
  }
}

.secondInfoBlockMobilePortal {
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  & div {
    max-width: 645px;
    text-align: center;
  }
}

.rightPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  @media (min-width: 0px) and (max-width: 767px) {
    padding: 30px 0 30px 0;
  }
}

.gameListWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  background: var(--main);
  border-radius: 30px;
  padding: 30px;
  overflow: auto;
  height: 100%;
  &.multiple {
    justify-content: flex-start;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: 0px) and (max-width: 768px) {
    padding: 10px;
  }
  @media (min-width: 1400px) and (max-width: 1599px) {
    justify-content: center;
  }
}

.gameList {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  @media (min-width: 0px) and (max-width: 1599px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media (min-width: 0px) and (max-width: 768px) {
    gap: 12px;
  }
}

.gameItem {
  height: 143px;
  background-color: var(--game-item-bg);
  border-radius: 25px;
  width: 100%;
  padding: 14px;
  display: flex;
  align-items: center;
  &:hover {
    transform: translateY(-10px);
    transition: transform 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  }
  @media (min-width: 0px) and (max-width: 1599px) {
    width: 100%;
    max-width: 491px;
  }
}

.gameItemContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-width: 180px;
  @media (min-width: 0px) and (max-width: 1599px) {
    width: 100%;
    align-items: center;
    min-width: auto;
  }
}

.gameItemIcon {
  display: block;
  width: 115px;
  height: 115px;
  border-radius: 21px;
  background-color: #fff;
  flex-shrink: 0;
  margin-right: 20px;
}

.gameItemName {
  font-family: "Kanit", sans-serif;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  max-width: 180px;
  margin-top: 10px;
  letter-spacing: -0.01em;

  @media (min-width: 768px) {
    font-size: 20px;
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    display: block;
    max-width: 100%;
  }
}

.gameItemButton {
  width: 100%;
  max-width: 180px;
  height: 45px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
  background-color: var(--main);
  color: #fff;
  border: none;
  padding: 0;
  border-radius: 25px;
  margin-bottom: 4px;
  outline: none;
  transition: transform 133ms ease-in-out, background-color 133ms ease-in-out;
  flex-shrink: 0;
  &:hover {
    cursor: pointer;
    background-color: #025e5d;
    transition: transform 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  }
  @media (min-width: 0px) and (max-width: 1599px) {
    width: 100%;
    max-width: 180px;
  }
}
