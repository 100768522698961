.popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(8, 77, 40, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.popupInner {
  position: relative;
  background-color: #fff;
  width: 528px;
  min-height: 433px;
  box-shadow: 0px 9px 21px 0px #b1d2ce33;
  border-radius: 30px;
  margin-top: 164px;
  @media (min-width: 0px) and (max-width: 767px) {
    width: 90%;
    margin-top: 20%;
  }
}

.inputField {
  width: 80%;
  height: 60px;
  padding: 18px;
  margin: 20px 0;
  font-size: 16px;
  border: 1px solid #DEE0E4;
  border-radius: 15px;
}

.inputField:focus {
  outline: none !important;
  border: 2px solid #85C800;
}

.popupContent {
  padding: 120px 60px 30px 60px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  @media (min-width: 0px) and (max-width: 375px) {
    padding: 30px;
    padding-top: 80px;
  }
}

.popupGameIcon {
  position: absolute;
  top: -5%;
  left: 50%;
  display: block;
  width: 115px;
  height: 115px;
  border-radius: 21px;
  background-color: grey;
  transform: translateX(-50%);
  @media (min-width: 0px) and (max-width: 375px) {
    top: -10%;
  }
}

.popupCloseBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  display: block;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  opacity: 0.4;
  transition: opacity 133ms ease-in-out;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.popupTextContainer {
  max-width: 344px;
  margin: 100px auto 0;
}

.popupHeading {
  margin: 0 0 10px 0;
  font-family: "Kanit", sans-serif;
  font-size: 30px;
  line-height: 30px;
  font-weight: 500;
  text-align: center;
}

.popupSubHeading {
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
}

.actionBtnWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin: 20px auto 0;
  width: auto;
  max-width: 270px;
  min-width: 180px;
}

.popupLoader {
  width: 100px;
  height: 100px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a12" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="%2385C800"></stop><stop offset=".3" stop-color="%2385C800" stop-opacity=".9"></stop><stop offset=".6" stop-color="%2385C800" stop-opacity=".6"></stop><stop offset=".8" stop-color="%2385C800" stop-opacity=".3"></stop><stop offset="1" stop-color="%2385C800" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(%23a12)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="%2385C800" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70"></circle></svg>');
}
