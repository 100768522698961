.footer {
  position: sticky;
  z-index: -1;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: var(--footer-height);
  background-color: var(--footer-bg);
  color: var(--footer-text);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  @media (min-width: 0px) and (max-width: 819px) {
    position: sticky;
    z-index: -1;
    flex-wrap: wrap;
    height: auto;
    padding: 50px 20px 20px;
  }
  @media (min-width: 820px) and (max-width: 1199px) {
    position: sticky;
    z-index: -1;
    flex-wrap: wrap;
    height: auto;
    padding: 50px 20px 20px;
  }
}

.footerLogo {
  width: 132px;
  height: 103px;
  @media (min-width: 0px) and (max-width: 472px) {
    margin-top: 16px;
    order: 2;
  }
}

.footerNav {
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (min-width: 0px) and (max-width: 472px) {
    margin-top:30px;
  }
}

.footerNavLink,
.footerSocialLink {
  color: var(--footer-text);
}

.footerSocialLink::after,
.footerNavLink::before {
  opacity: 0;
  transition: opacity 133ms ease-in-out;
  background: var(--footer-text);
}

.footerNavLink {
  position: relative;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  padding-left: 20px;
  &:before {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
  }
}

.footerSocials {
  display: flex;
  gap: 75px;
  margin-bottom: 100px;
  @media (min-width: 0px) and (max-width: 1199px) {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 100%;
    justify-content: space-around;
    gap: 15px;
  }
}

.footerSocialLink {
  text-decoration: none;
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
  }
  &:hover {
    &::after {
      opacity: 1;
    }
  }
}

.copyright {
  opacity: 0.3;
  @media (min-width: 0px) and (max-width: 1199px) {
    text-align: center;
  }
}

.socialsAndCopyWrapper {
  @media (min-width: 0px) and (max-width: 1199px) {
    width: 100%;
    order: 3;
  }
}
