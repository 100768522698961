:root {
  --footer-bg: #34373e;
  --footer-text: #fff;
  --main: #85c800;

  --game-item-bg: #fff;

  --footer-height: 265px;

  --btn-primary-bg-color: var(--main);
  --btn-primary-hover-bg-color: #025e5d;
  --btn-primary-text-color: #fff;

  --btn-secondary-bg-color: #eff5f5;
  --btn-secondary-hover-bg-color: #d2dfde;
  --btn-secondary-text-color: #34373e;
}
