.button {
  width: 100%;
  height: 45px;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
  border: none;
  padding: 0;
  border-radius: 25px;
  outline: none;
  padding: 0 20px;
  transition: background-color 133ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  &:hover {
    cursor: pointer;
  }
  &.primary {
    background-color: var(--btn-primary-bg-color);
    color: var(--btn-primary-text-color);
    &:hover {
      background-color: var(--btn-primary-hover-bg-color);
    }
  }
  &.secondary {
    background-color: var(--btn-secondary-bg-color);
    color: var(--btn-secondary-text-color);
    &:hover {
      background-color: var(--btn-secondary-hover-bg-color);
    }
  }
  &.signIn {
    background-color: #000;
    color: #fff;
  }
  &:disabled {
    background-color: #ccc; /* Example gray background for disabled state */
    color: #666; /* Example text color for disabled state */
    cursor: not-allowed; /* Change cursor to not-allowed */
    opacity: 0.5; /* Lower opacity for disabled state */    
  }
}
